import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
//styles
import './styles.scss';



class TeamMember extends Component {

  static defaultProps = {
    data: {},
  };

  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {

    const member = this.props.data;
    const memberImage = member.image && member.image.fixed.src;
    const memberImageLarge = member.image && member.image.fixed.src;

    return (
        <li className="team-member">
          <div className="team-member-image">
            <img src={memberImage} srcSet={`${memberImage} 1x, ${memberImageLarge} 2x`} alt={member.name} />
          </div>
          <div className="team-member-info">
            <div className="team-member-info-inner">
              <strong className="team-member-name">{member.name}</strong>
              <span className="team-member-title">{member.jobTitle}</span>
              <div className="team-member-social">{member.socialLinks && this.renderSocialLinks(member.socialLinks)}</div>
            </div>
          </div>
        </li>
    );
  };

  renderSocialLinks = (socialLinks) => {
    return socialLinks.map((link, index) => {
      let socialIcon = '';

      if (link.includes('facebook')) {
        socialIcon = faFacebookSquare;
      }
      else if (link.includes('linkedin')) {
        socialIcon = faLinkedin;
      }

      else if (link.includes('twitter')) {
        socialIcon = faTwitterSquare;
      }

      return (
          <a key={index} href={link} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={socialIcon} />
          </a>
      );
    });
  };
}

export default TeamMember
