import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Link as ScrollLink, Events } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';
import { FB_URL, IN_URL, SITE_NAV, TWITTER_URL } from '../constants';

//styles
import './Header.scss';

//Logo
import Logo from '../../images/cytec-logo.png';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sticky: false,
      mobileNavActive: false,
    };

    this.headerNav = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    Events.scrollEvent.register('begin', () => {
      this.setState({ mobileNavActive: false });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    Events.scrollEvent.remove('begin');
  }

  render() {
    return (
      <header
        className={`ct-header-wrap ${this.state.sticky ? 'sticky' : ''}`}
        ref={this.headerNav}
      >
        <div className="wrapper">
          <div className="ct-header-inner">
            <Link to="/">
              <img className="ct-logo" src={Logo} alt={this.props.siteTitle} />
            </Link>

            <button
              className={`hamburger hamburger--elastic ${
                this.state.mobileNavActive ? 'is-active' : ''
              }`}
              onClick={this.toggleMobileNav}
              type="button"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>

            <nav className={`ct-header-nav ${this.state.mobileNavActive ? 'visible' : ''}`}>
              <ul className="ct-header-nav-list">{this.renderNav(SITE_NAV)}</ul>
              <div className="ct-social-nav-mobile">
                {this.renderSocialNav()}
              </div>
            </nav>
            {this.renderSocialNav()}
          </div>
        </div>
      </header>
    );
  }

  renderNav = navList => {
    const headerHeight = this.headerNav.current
      ? this.headerNav.current.offsetHeight
      : 90;

    //alternative page
    if (this.props.alt) {
      return navList.map((navItem, index) => {
        return (
          <li key={index}>
            <Link to={`${navItem.link ? `#${navItem.link}` : navItem.url}`}>
              {navItem.title.toUpperCase()}
            </Link>
          </li>
        );
      });
    }

    return navList.map((navItem, index) => {
      if (navItem.url) {
        return (
          <li key={index}>
            <Link to={navItem.url}>{navItem.title.toUpperCase()}</Link>
          </li>
        );
      } else {
        return (
          <li key={index}>
            <ScrollLink
              activeClass="active"
              to={navItem.link}
              spy={true}
              smooth={true}
              offset={-headerHeight}
              duration={500}
            >
              {navItem.title.toUpperCase()}
            </ScrollLink>
          </li>
        );
      }
    });
  };

  renderSocialNav = () => {
    return (
        <ul className="ct-social-nav">
          <li className="ct-social-nav-item">
            <a href={IN_URL} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </li>
          <li className="ct-social-nav-item">
            <a href={FB_URL} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
          </li>
          <li className="ct-social-nav-item">
            <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitterSquare} />
            </a>
          </li>
        </ul>
    );
  };

  handleScroll = () => {
    this.setState({ sticky: window.scrollY > 0 });
  };

  toggleMobileNav = () => {
    this.setState(prevState => ({
      mobileNavActive: !prevState.mobileNavActive,
    }));
  };
}

Header.propTypes = {
    siteTitle: PropTypes.string.isRequired,
    alt: PropTypes.bool,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
