import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { NotificationManager } from 'react-notifications';
import { NOTIFICATIONS } from '../constants';

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    recaptcha: '',
    isSuccessVisible: false,
    errors: [],
  };

  recaptchaRef = React.createRef();

  render() {
    return (
      <form
        id="ct-contact-form"
        className="ct-form"
        autoComplete="off"
        onSubmit={e => this.handleSubmit(e)}
      >
        <label
          className={`ct-form-field ${this.state.errors.name ? 'error' : ''}`}
        >
          <input
            type="text"
            name="name"
            placeholder="Name"
            onChange={this.handleChange}
            required
          />
        </label>
        <label
          className={`ct-form-field ${this.state.errors.email ? 'error' : ''}`}
        >
          <input
            type="email"
            name="email"
            placeholder="Email"
            onChange={this.handleChange}
            required
          />
        </label>
        <label
          className={`ct-form-field ${
            this.state.errors.subject ? 'error' : ''
          }`}
        >
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            onChange={this.handleChange}
          />
        </label>
        <label
          className={`ct-form-field ${
            this.state.errors.message ? 'error' : ''
          }`}
        >
          <textarea
            name="message"
            placeholder="Message"
            onChange={this.handleChange}
            required
          />
        </label>

        <strong>
          GDPR Agreement<sup>*</sup>
        </strong>
        <label className="ct-form-field" htmlFor="gdpr_agreement">
          <input
            type="checkbox"
            name="gdpr_agreement"
            id="gdpr_agreement"
            required
          />
          I consent to having this website store my submitted information so
          they can respond to my inquiry.
        </label>

        <div className="ct-form-field">
          <ReCAPTCHA
            ref={this.recaptchaRef}
            size="invisible"
            sitekey="6Lc7msAUAAAAAIvhJ54wSqrmK_BlTnLEnMMy1EOR"
            onChange={this.handleCaptchaChange}
          />
        </div>

        <button className="ct-button" type="submit">
          Submit
        </button>
      </form>
    );
  }

  handleChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value,
    });
  };

  handleCaptchaChange = value => {
    this.setState({ recaptcha: value });
    this.handleFetch();
  };

  handleFetch = () => {
    const form = document.getElementById('ct-contact-form');
    const url = 'https://backend.cytec.bg/api/contact/submit';
    const data = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message,
      recaptcha: this.state.recaptcha,
    };

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      dataType: 'json',
    })
      .then(res => res.json())
      .then(res => {
        let errorTitle = '';
        let errorBody = '';
        let errorsArr = [];

        if (res.status.code === 0) {
          //reset error styling
          this.setState({ errors: [] });
          //reset form fields
          form.reset();
          //show success notification
          NotificationManager.success(
            NOTIFICATIONS.SUCCESS.body,
            NOTIFICATIONS.SUCCESS.title
          );
        } else {
          if (res.errors) {
            // reset recaptcha
            this.recaptchaRef.current.reset();
            //set error styling to fields
            this.setState({ errors: res.errors });
            //set error messages
            errorTitle = res.status.message;
            errorsArr = Object.values(res.errors);
            errorBody = errorsArr.map(error => {
              return Object.values(error)[0];
            });
          }
          //show error notification
          NotificationManager.error(
            errorBody.toString() || NOTIFICATIONS.ERROR.body,
            errorTitle || NOTIFICATIONS.ERROR.title
          );
        }
      });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.recaptchaRef.current.execute();
  };
}

export default ContactForm;
