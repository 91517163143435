import React, { Component } from 'react';

//styles
import './styles.scss';

class About extends Component {
    render() {

        return (
            <section className="about-section" id="section-about">
                <span className="overlay dark" />

                <div className="about-section-inner wrapper">
                    <div>
                        <h2 className="ct-title">CYTEC</h2>
                        <p>
                            is a young and fast-growing IT company based in Eastern Europe.
                            Our professional field covers the most modern technologies in enterprise web, mobile and embedded development.
                        </p>
                        <p>
                            We have great passion for our work, we strive to drive projects to completion and provide long term maintenance after release.
                            We also like to take our time to understand the specific needs of every project down to the smallest detail.
                        </p>
                        <span className="line" />
                    </div>
                </div>
            </section>
        );
    };
}

export default About
