import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { NotificationContainer } from 'react-notifications';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faDatabase,
  faFileCode,
  faLaptopCode,
  faLaptopMedical,
  faProjectDiagram,
  faServer,
  faShoppingCart,
  faTable,
  faTools,
} from '@fortawesome/free-solid-svg-icons';
//components
import Header from './Header/Header.js';
import Footer from './footer';
//styles
import './layout.scss';
import 'react-notifications/lib/notifications.css';

library.add(
  fab,
  faLaptopMedical,
  faFileCode,
  faDatabase,
  faLaptopCode,
  faTools,
  faServer,
  faProjectDiagram,
  faShoppingCart,
  faTable
);

const Layout = ({ children, alt }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,700"
            rel="stylesheet"
          />
        </Helmet>

        <Header siteTitle={data.site.siteMetadata.title} alt={alt} />

        <main className="main-body">
          <NotificationContainer />
          {children}
          <Footer siteTitle={data.site.siteMetadata.title} />
        </main>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  alt: PropTypes.bool,
};

export default Layout;
