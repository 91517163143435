import React, { Component } from 'react';

//styles
import './styles.scss';

class Hero extends Component {
    render() {

        return (
            <section className="hero-section" id="section-home">
                <span className="overlay dark" />
                <div className="hero-section-inner wrapper">
                    <h1>
                        There is a way out of every <strong className="colored">box</strong>,
                        a solution to every <strong className="colored">puzzle</strong>; it's just a matter of finding it.
                    </h1>
                    <span className="line" />
                </div>
            </section>
        );
    };
}

export default Hero
