import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
//styles
import './styles.scss';
import TeamMember from '../TeamMember';

const Team = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulTeamMember(sort: { fields: orderNumber, order: ASC }) {
          edges {
            node {
              name
              image {
                fixed(width: 440, height: 440, quality: 100) {
                  src
                }
              }
              jobTitle
              socialLinks
            }
          }
        }
      }
    `
  );

  return (
    <section className="team-section" id="section-team">
      <span className="overlay dark" />

      <div className="team-section-inner ct-section">
        <div className="ct-section-aside">
          <div className="wrapper">
            <h2 className="ct-title">
              OUR <strong className="colored">TEAM</strong>
            </h2>
            <p>
              Introducing our superstar development team!
            </p>
            <span className="line" />
          </div>
        </div>
        <div className="ct-section-content">
          <ul className="team-member-list">
            {data.allContentfulTeamMember.edges.map((node, index) => (
              <TeamMember key={index} data={node.node} />
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Team;
