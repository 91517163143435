export const FB_URL = "https://www.facebook.com/cytec.bg/";
export const TWITTER_URL = "https://twitter.com/CytecBG";
export const IN_URL = "https://www.linkedin.com/company/cytec-bg";
export const CT_ADDRESS = 'https://goo.gl/maps/cAEQfWuyPJCxAuJo9';

export const META_KEYWORDS = ['cytec', 'cytec bg', 'software development', 'website', 'mobile apps', 'engineering', 'technologies', '3d', 'design', 'embedded', 'electronics'];

export const NOTIFICATIONS = {
    SUCCESS: {
        title: 'Thank you for getting in touch!',
        body: 'We appreciate you contacting us!',
    },
    ERROR: {
        title: 'Something went wrong!',
        body: 'There are fields that require your attention!',
    }
};

export const SITE_NAV = [
    {
        title: 'Home',
        link: 'section-home',
    },
    {
        title: 'About',
        link: 'section-about',
    },
    {
        title: 'Services',
        link: 'section-services',
    },
    {
        title: 'Team',
        link: 'section-team',
    },
    // {
    //     title: 'Blog',
    //     link: 'section-blog',
    // },
    {
        title: 'Contact',
        link: 'section-contact',
    },
];

export const CT_SERVICES = [
    {
        title: 'Enterprise application development',
        icon: {
            name: 'laptop-medical',
        },
    },
    {
        title: 'Web & native webapp development',
        icon: {
            name: 'file-code',
        },
    },
    {
        title: 'REST & GraphQL API development',
        icon: {
            name: 'database',
        },
    },
    {
        title: 'Website Updates & Maintenance',
        icon: {
            name: 'tools',
        },
    },
    {
        title: 'AWS Consulting',
        icon: {
            type: 'fab',
            name: 'aws'
        },
    },
    {
        title: 'Custom programming',
        icon: {
            name: 'laptop-code',
        },
    },
    {
        title: 'DevOps CI & CD',
        icon: {
            name: 'server',
        },
    },
    {
        title: 'Domain management',
        icon: {
            name: 'project-diagram',
        },
    },
    {
        title: 'Payment Integration',
        icon: {
            name: 'shopping-cart',
        },
    },
    {
        title: 'Data Entry',
        icon: {
            name:  'table',
        },
    },
];
