import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Logo from "../images/cytec-logo.png";

const Footer = ({ siteTitle }) => (
    <footer className="ct-footer">
        <div className="wrapper">
            <div className="ct-footer-inner">
                <Link to="/">
                    <img className="ct-logo" src={Logo} alt={siteTitle} />
                </Link>
                <p>{new Date().getFullYear()} © {siteTitle} All Rights Reserved.</p>
            </div>
        </div>
    </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer
