import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
// import Blog from '../components/Blog';
import Contact from '../components/Contact';
import Team from '../components/Team';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
    />

    <Hero />
    <About />
    <Services />
    <Team />
    <Contact />
  </Layout>
);

export default IndexPage;
