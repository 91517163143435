import React, { Component } from 'react';

//styles
import './styles.scss';
import { CT_ADDRESS } from '../constants';
import ContactForm from './form';

class Contact extends Component {
    render() {

        return (
            <section className="contact-section ct-section" id="section-contact">
                <div className="ct-section-aside">
                    <div className="wrapper">
                        <h2 className="ct-title">GET IN <strong className="colored">TOUCH</strong></h2>

                        <ul className="ct-contact-info">
                            <li className="ct-contact-info-item">
                                <strong>Address</strong>
                                <p>
                                    <a className="ct-link" href={CT_ADDRESS} target="_blank" rel="noopener noreferrer">
                                        193A, 6th September Blvd. <br />
                                        Plovdiv, Bulgaria
                                    </a>
                                </p>
                            </li>
                            <li className="ct-contact-info-item">
                                <strong>Phone</strong>
                                <p>
                                    <a className="ct-link" href="tel:+359890329832">+359 890 32 98 32</a>
                                </p>
                            </li>
                            <li className="ct-contact-info-item">
                                <strong>Email</strong>
                                <p>
                                    <a className="ct-link" href="mailto:office@cytec.bg">office@cytec.bg</a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="ct-section-content">
                    <div className="ct-section-content-inner">
                        <ContactForm />
                    </div>
                </div>
            </section>
        );
    };
}

export default Contact
