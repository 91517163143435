import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//styles
import './styles.scss'
import { CT_SERVICES } from '../constants'

class Services extends Component {
  render() {
    return (
      <section className="services-section ct-section" id="section-services">
        <div className="ct-section-aside">
          <div className="wrapper">
            <h2 className="ct-title">
              OUR <strong className="colored">SERVICES</strong>
            </h2>
            <p>
              From custom software development with the latest tech to automated testing and deployment,
              we provide all types of services to help you build, sustain, and modernise your applications.
            </p>
          </div>
        </div>
        <div className="ct-section-content">
          <div className="ct-section-content-inner">
            <div className="services-list-wrap">
              <ul className="services-list">
                {CT_SERVICES.map((service, index) => (
                    <li className="services-list-item" key={index}>
                  <span className="services-list-item-icon">
                    <FontAwesomeIcon
                        icon={[service.icon.type, service.icon.name]}
                    />
                  </span>
                      <strong>{service.title}</strong>
                    </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Services
